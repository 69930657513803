$(document).ready(function() {

    var $openingHours = $(".openinghours");

    $(".opening-tab-js").hover(function () {
            $openingHours.fadeIn("slow");
        },
        function () {
            $openingHours.fadeOut();
        });

    $(".opening-button-js").click(function () {
        if($openingHours.hasClass('is-visible')) {
            $openingHours.fadeOut("slow");
        } else {
            $openingHours.fadeIn("slow");
        }
        $openingHours.toggleClass('is-visible');

    });

    $(".click-allow-events-js").click(function(){
        $(this).children().removeClass('prevent-pointer-events');
    });


    var sideslider = $('[data-toggle=collapse-side]');
    var sel = sideslider.attr('data-target');
    var sel2 = sideslider.attr('data-target-2');
    sideslider.click(function(event){
        $(sel).toggleClass('in');
        $(sel2).toggleClass('out');

        setTimeout(function () {
            $('.dropdown.active').toggleClass('open');
            $('.dropdown-toggle.active').attr('aria-expanded',true);
        },500);


        $('#mobile-nav-overlay').toggleClass('hide');
        $('#mobile-nav-overlay').toggleClass('show');
    });

    // contact form ajax send
    $('.js-contact-form').on('submit',function(e){
        e.preventDefault();

        $('.form-group').removeClass('has-error');

        var $form = $(this);
        var url = $form.attr('action');
        var formData = new FormData($form[0]);
        var $response = $form.parent().find('.js-form-response').removeClass('alert-danger');

        $.ajax({
            url : url,
            type : 'POST',
            data : formData,
            dataType : 'json',
            contentType: false,
            processData: false,
            success : function(data) {
                if (data.status == 1) {
                    $response.html(data.msg).addClass('alert-success').removeClass('hide');
                    $form.remove();
                } else {
                    $response.html(data.msg).addClass('alert-danger').removeClass('hide');
                    if(data.errors != undefined) {
                        for (var error in data.errors) {
                            //console.log(error);
                            $form.find('.form-group.' + error).addClass('has-error');
                        }
                    }
                }
                //console.log(data);
            },
            error : function(XHR, textStatus, errorThrown) {
                $response.html('Beim Absenden ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.').addClass('alert-danger').removeClass('hide');
            }
        });

    });

    jamedaSiegelPosition();

});


$(window).resize(function(){
    jamedaSiegelPosition()

});

$(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});




function jamedaSiegelPosition() {
    var width = $('html').width();
    console.log(width);
    var $side_menu = $('.side-menu');
    var $sub_content = $('#sub-content');
    if ( width < 990 ) {
        $('#jameda-siegel-wrapper.position-side-menu')
            .removeClass('position-side-menu')
            .addClass('position-sub-content')
            .appendTo($sub_content);
    } else {
        $('#jameda-siegel-wrapper.position-sub-content')
            .removeClass('position-sub-content')
            .addClass('position-side-menu')
            .appendTo($side_menu);
    }

}
